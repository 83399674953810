.textarea {
  font-family: Montserrat;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  resize: none;

  &:not(.textareaError):focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: #aaa;
  }

  &Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
  }

  &Label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
  }

  &Error {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.75);
  }

  &ErrorMessage {
    color: red;
    font-size: 14px;
  }
}
