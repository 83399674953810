.xlsx {
  text-decoration: none;
  font-size: 18px;
  margin-right: 40px;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    text-decoration: none;
  }

  &__icon {
    color: green;

    &.pdf {
      color: rgb(167, 0, 0);
    }
  }
}
