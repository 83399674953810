.delicoffeStart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(183.03deg, #00d2ff -4.2%, #2c3cfe 96.79%);
}

.wrapper {
  text-align: center;
}

.delicoffeStartWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 26px;
}

.startButton {
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);
  background-color: white;
  font-family: Montserrat;
  font-size: 16px;
  color: #158afe;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
