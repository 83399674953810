.searchbar {
  width: 100%;
}

.searchbarWrapper {
  position: relative;
  width: 100%;

  div {
    width: 100%;
  }

  input {
    width: 100%;
    padding: 12px 15px 12px 38px;

    &:focus {
      border: none;
    }
  }

  .MuiInputBase-root {
    border: none !important;
  }
}
