.FormWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 450px;

  label {
    text-align: left;
  }
}

.FormField {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 15px;

  input {
    border-radius: 8px;

    &:focus {
      border-color: rgba(255, 255, 255, 0.7);
      outline: 0;
      box-shadow: 0px 10px 20px rgb(0 0 0 / 15%) !important;
    }
  }
}

.FormButtonWrapper {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin-top: 15px;
}

.InputVat {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  column-gap: 10px;

  label {
    margin: 0;
  }

  input {
    width: auto;
  }
}
