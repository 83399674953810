.ButtonElement {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
  border-radius: 25px;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);
  background-color: white;
  font-family: Montserrat;
  font-size: 16px;
  color: #158afe;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  white-space: nowrap;
  transition: transform 0.1s ease-in 0s;
  transform: perspective(1px) scale(1) translateZ(0px);

  &:hover {
    transform: perspective(1px) scale(1.01) translateZ(0px);
  }
}

.Buttondisabled {
  opacity: 0.7;
  pointer-events: none;
}

.ButtonSecundary {
  background: black;
  color: white;
  width: auto;
  padding: 10px 29px;
  height: auto;
}

.ButtonSubmit,
.ButtonUpdate,
.ButtonCancel,
.ButtonDelete {
  width: fit-content !important;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  height: 30px;
  padding: 0 30px;
  font-size: 14px;
  margin: unset;
  box-shadow: unset;
}

.ButtonSubmit {
  background: #0095ff;
}

.ButtonCancel {
  background: #333;
}

.ButtonUpdate {
  background: #0095ff;
}

.ButtonDelete {
  background: red;
}
