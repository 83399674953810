@import 'styles/utils.scss';

.Switch {
  display: block;

  &Input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    cursor: pointer;

    &:checked + .SwitchLabel .SwitchSlider {
      background-color: $color-secondary;

      &:before {
        transform: translateX(20px);
      }
    }
  }

  &Label {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    font-size: 14px;

    cursor: pointer;

    &--empty {
      display: flex;
      font-weight: 500;
    }
  }

  &SliderWrapper {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0;
  }

  &Slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d6dde2;
    border-radius: 36px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: $primary-background;
      border-radius: 50%;
      transform: translateX(0);
      transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }
  }

  &Text {
    font-family: Montserrat;
    font-weight: 500;
    margin-left: 7px;
    user-select: none;
    white-space: nowrap;
  }
}
