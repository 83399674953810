// Base
@import 'base/base';
@import 'base/normalize';

// Toasts
@import 'toasts';

*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  height: 100vh;
}

.DashboardContainer {
  display: flex;
  background: #f2f2f2;

  .MainContent {
    padding: 20px;
  }
}

.MainContainer {
  width: calc(100% - 65px);
  display: flex;
  flex-direction: column;
}

.MainContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.LoginContainer {
  height: 100%;

  .MainContainer {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.RemoveConfirm {
  background: red;
  color: white;
  font-weight: normal;
  border-radius: 6px;
  width: auto;
  height: 30px;
  padding: 0 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.MuiPaper-root {
  margin-bottom: 0 !important;
}

div[role='tabpanel'] {
  overflow: auto;
}
