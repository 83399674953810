@import 'styles/utils.scss';

.Checkbox {
  &Check {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    cursor: pointer;

    &:checked + .CheckboxLabel .CheckboxBox {
      background-color: $color-primary;
      border: none;

      &:after {
        display: block;
      }
    }
  }

  &Label {
    display: grid;
    grid-template-columns: 18px 1fr;
    align-items: center;
    font-size: 14px;

    cursor: pointer;

    &--empty {
      display: flex;
      font-weight: 500;
    }
  }

  &Box {
    background-color: $color-text-white-base;
    border: 1px solid $color-text-black-brighter-2;
    border-radius: 3px;
    height: 16px;
    width: 16px;
    transition: all 150ms linear;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid $color-text-white-base;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &Text {
    font-family: Montserrat;
    font-weight: 500;
    margin-left: 5px;
  }
}
