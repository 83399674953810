.new-contract {
  background-color: white;
  padding: 30px;
  border-radius: 8px;

  &__header {
    margin-bottom: 20px;
  }

  &__subheader {
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__basic-data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__basic-data-input {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    width: calc(33% - 30px);
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    label {
      display: block;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
    }

    .MuiInputBase-input {
      height: auto;
    }
  }

  &__switches {
    margin-bottom: 20px;
  }

  &__error-message {
    color: red;
    font-size: 14px;
  }

  &__basic-date {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    width: calc(33% - 30px);
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    label {
      display: block;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
    }

    & > div {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      & > div {
        font-size: 14px;
      }
    }

    &--error {
      & > div {
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.75);
      }
    }

    input {
      font: 500 14px Montserrat !important;
      height: unset !important;
      color: #495057;
      padding: 0.475rem 0.75rem;
    }

    svg {
      width: 0.8em;
      height: 0.8em;
    }

    fieldset {
      border: none !important;
    }

    .MuiInputBase-input {
      height: auto;
    }
  }

  &__title {
    margin: 10px 0;
  }

  &__new-line {
    margin-bottom: 15px;
  }

  &__bottom-fields {
    margin-top: 30px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    column-gap: 20px;
  }
}
