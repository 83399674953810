.LoginFormWrapper {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 30px;
}

.LoginLogo {
  margin-bottom: 40px;
}

.FormWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 450px;

  label {
    text-align: left;
    color: white;
  }
}

.FormButtonWrapper {
  display: flex;
  margin: 0 !important;
  right: 0;
  left: 0;
}

.FormActionWrapper {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #ffffff25;
  padding-top: 20px;
}

.FormForgotPassword {
  font-family: Montserrat;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  letter-spacing: 0.7px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: white;
  }
}

.FormField {
  position: relative;

  input {
    appearance: none;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    background: transparent;
    height: 44px;
    color: white;

    &:focus {
      border-color: rgba(255, 255, 255, 0.7);
      outline: 0;
      box-shadow: 0px 10px 20px rgb(0 0 0 / 15%) !important;
    }

    &::placeholder {
      color: white;
    }
  }
}

.ResetPasswordTitle {
  font-weight: bold;
  color: white;
  font-size: 30px;
}
