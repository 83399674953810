.planning {
  margin: 20px 0;
}
.MuiTableContainer-root {
  padding: 0 10px;
}

.MuiTableCell-head {
  input {
    min-width: 60px;
  }

  input[id='client'] {
    min-width: 200px;
  }
}
