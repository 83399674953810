.FormContainerText {
  margin-bottom: 15px;
  font-size: 15px;
}

.FormButtonWrapper {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin-top: 15px;
}
