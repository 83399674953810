.LineItemNewLine {
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  row-gap: 15px;
  border: 1px solid #0095ff;
  border-radius: 4px;
  padding: 20px;
  margin-top: 15px;

  &Form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    margin-bottom: 10px;

    &Select {
      max-width: 210px !important;
    }

    & > div {
      max-width: 200px;

      &:last-child {
        max-width: 210px;
      }
    }

    &Label {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 8px;
      display: block;
    }

    &Date {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 7px;

      & > div {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        & > div {
          font-size: 14px;
        }
      }

      label {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
      }

      &Error {
        & > div {
          box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.75);
        }
      }

      &ErrorMessage {
        color: red;
        font-size: 14px;
      }

      input {
        font: 500 14px Montserrat !important;
        height: unset !important;
        color: #495057;
        padding: 0.475rem 0.75rem;
      }

      svg {
        width: 0.8em;
        height: 0.8em;
      }

      fieldset {
        border: none !important;
      }
    }
  }

  button {
    grid-column: 6/6;
  }
}

.LineItemNewLineTitle {
  margin: 0;
  font-weight: bold;
}

.LineItemFormAction {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;

  button {
    max-width: 210px;
  }
}

.LineItemFormFooter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 15px;
}

.LineItemFormFooterError {
  color: red;
  font-size: 14px;
  line-height: 17px;
}
