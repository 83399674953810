.replacements-list {
  &--empty {
    padding: 20px;
  }

  &__action-btn {
    font-family: Montserrat;
    background-color: #0095ff;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 20px;
    cursor: pointer;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: #0079cf;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}
