.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.42);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.modalWrapper {
  position: relative;
  min-width: 650px;
  max-width: 80vw;
}

.modalNarrow {
  min-width: auto;
}

.modalContent {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #333333;
  background-color: white;
  padding: 15px 15px 15px 20px;

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.modalBody {
  position: relative;
  max-height: 80vh;
  overflow: auto;
  padding: 20px;
  font-size: 12px;
}

.modalButton {
  padding: 5px 15px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  border: 1px solid #cccccc;
  background-color: white;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  &Submit {
    background-color: #0095ff;
    border-color: #0095ff;
    color: white;

    &:hover {
      background-color: darken(#0095ff, 10%);
      border-color: darken(#0095ff, 10%);
    }
  }
}

.modalClose {
  font-size: 14px;
  cursor: pointer;
  color: #666;
}
