.line-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px;
  border-bottom: 1px solid #0095ff;

  &:last-child {
    border-bottom: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__quality {
    margin: 0;
  }

  &__metadata {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  &__metadata-line {
    font-size: 14px;
  }

  &__metadata-title {
    font-weight: 600;
  }

  &__actions {
    display: flex;
    column-gap: 30px;
  }

  &__actions-icon {
    font-size: 22px;
    color: #0095ff;
    cursor: pointer;
    transition: ease-in-out 150ms;

    &:hover {
      transform: scale(1.1);
    }
  }
}
