.FiscalData {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e6e6e6;
}

.ClientDataTitle {
  font-size: 15px;
  color: #222b45;
  font-weight: 600;
  margin-bottom: 30px;
}

.FormWrapper {
  display: grid;
  grid-template-columns: repeat(3, 230px);
  row-gap: 15px;
  column-gap: 15px;

  label {
    text-align: left;
    font-size: 14px;
  }
}

.InputAddress {
  &:nth-child(3) {
    grid-column: 2/1;
  }
}

.InputVat {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  column-gap: 10px;
  grid-column: 4 / 1;

  label {
    margin: 0;
  }

  input {
    width: auto;
  }
}

.ClientFormButtons {
  margin-top: 15px;
  display: flex;
  column-gap: 15px;
  grid-column: 4/1;
}

.ContactFormButtons {
  grid-column: 1 / 4;
}
