.orders {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    background: white;
    border-radius: 4px;
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
  }
}
