.Invoices {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.InvoicesList {
  background: white;
  border-radius: 4px;
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.InvoicesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
