.loader {
  margin: 6em auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(37, 37, 37, 0.15);
  border-right: 1.1em solid rgba(37, 37, 37, 0.15);
  border-bottom: 1.1em solid rgba(37, 37, 37, 0.15);
  border-left: 1.1em solid #158afe;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loaderInverse {
  border-top: 1.1em solid rgb(179 215 237 / 60%);
  border-right: 1.1em solid rgb(179 215 237 / 60%);
  border-bottom: 1.1em solid rgb(179 215 237 / 60%);
  border-left: 1.1em solid #fff;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader.small,
.loader.small:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 4em auto;
  border-top: 0.8em solid rgba(37, 37, 37, 0.15);
  border-right: 0.8em solid rgba(37, 37, 37, 0.15);
  border-bottom: 0.8em solid rgba(37, 37, 37, 0.15);
  border-left: 0.8em solid #158afe;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loaderInverse.small {
  border-top: 0.8em solid rgb(179 215 237 / 60%);
  border-right: 0.8em solid rgb(179 215 237 / 60%);
  border-bottom: 0.8em solid rgb(179 215 237 / 60%);
  border-left: 0.8em solid #fff;
}
.loader.tiny,
.loader.tiny:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  margin: 0 auto;
}
.loader.micro,
.loader.micro:after {
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  margin: 0 auto;
  -webkit-animation: load8 0.4s infinite linear;
  animation: load8 0.4s infinite linear;
  border-top: 0.5em solid rgba(37, 37, 37, 0.15);
  border-right: 0.5em solid rgba(37, 37, 37, 0.15);
  border-bottom: 0.5em solid rgba(37, 37, 37, 0.15);
  border-left: 0.5em solid #158afe;
}
.loaderInverse.micro {
  border-top: 0.5em solid rgb(179 215 237 / 60%);
  border-right: 0.5em solid rgb(179 215 237 / 60%);
  border-bottom: 0.5em solid rgb(179 215 237 / 60%);
  border-left: 0.5em solid #fff;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
