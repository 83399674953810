.toast__alert {
  z-index: 9999;
  position: fixed;
  top: 75px;
  right: 25px;
  padding: 15px 20px;
  background-color: #333333;
  border: none;
  border-radius: 3px;
  color: white;
  opacity: 0.95;
  display: none;

  &--success:before,
  &--error:before,
  &--warning:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &--success:before {
    background-color: #4cae4c;
  }

  &--error:before {
    background-color: #d5403b;
  }

  &--warning:before {
    background-color: #f49d21;
  }

  .fa-times {
    margin-right: 10px;
    cursor: pointer;
  }
}
