.line-items {
  &__items {
    border: 1px solid #0095ff;
    border-radius: 4px;

    .LineItemNewLine {
      border: none;
      border-radius: 0;
      margin-top: 0;

      &:not(:last-child) {
        border-bottom: 1px solid #0095ff;
      }
    }
  }

  &__empty {
    border: 1px solid #0095ff;
    border-radius: 4px;
    text-align: center;
    padding: 20px;

    &--error {
      border-color: red;
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.75);
    }

    button {
      margin: 0 auto;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__error-message {
    display: block;
    color: red;
    font-size: 14px;
    margin-top: 7px;
  }
}
