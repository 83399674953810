.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7px;

  &Searchbar {
    padding: 10px 10px 5px 10px;
  }

  &Wrapper {
    width: 100%;
    position: relative;
  }

  &Trigger {
    min-width: 120px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 0.475rem 0.75rem;
    cursor: pointer;
    border: 1px solid #ced4da;
    vertical-align: middle;
    user-select: none;
    transition: box-shadow 0.3s ease;

    &Disabled {
      cursor: not-allowed;
      background-color: #eee;
    }

    &Error {
      box-shadow: 0 0 0 3px red;
    }
  }

  &Value {
    color: #495057;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Placeholder {
    color: #aaa;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
  }

  &Caret {
    font-size: 16px !important;
    color: #1d1d35;
    transition: transform 0.2s ease;

    &Visible {
      transform: rotate(180deg);
    }
  }

  &OptionsWrapper {
    visibility: hidden;
    min-width: 150px;
    max-height: 200px;
    overflow: auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
    z-index: 11;

    &Active {
      visibility: visible;
      z-index: 9999;
    }

    input {
      font-size: 14px;
      padding: 5px 15px 5px 30px;
    }

    svg {
      left: 8px !important;
      font-size: 16px !important;
    }
  }

  &Options {
    list-style: none;
    padding: 0;
    margin: 5px 0;
  }

  &Option {
    color: #1d1d35;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 15px;
    display: block;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: #f3f6f8;
    }

    &Active {
      background-color: #dde9f0;
      font-weight: 600;
    }
  }

  &ErrorMessage {
    color: red;
    font-size: 14px;
  }
}
