.PermissioTitle {
  margin-bottom: 10px;
}

.PermissionWrapper {
  display: flex;
  column-gap: 30px;
  margin-bottom: 30px;
}

.PermissionInput {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  column-gap: 10px;

  label {
    margin: 0;
  }

  input {
    width: auto;
  }
}
