/* Colores */
// azul #0095ff;
// azul hover #232f58

.SideBar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0095ff;
  width: 65px;
  max-width: 65px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;

  &Open {
    width: 185px;
    max-width: 185px;

    & + .MainContainer {
      width: calc(100% - 185px);
    }

    .SideBarMenu {
      row-gap: 0;
    }

    .SideBarItemLink {
      width: unset;
      height: unset;
      display: flex;
      align-items: center;
      justify-content: unset;
      column-gap: 10px;
    }

    .SideBarItemLink {
      padding: 10px 30px 10px 20px;
      border-radius: 0;
    }

    .SideBarBottom {
      width: 100%;

      .SideBarItemLink {
        justify-content: center;
      }
    }
  }

  &Top {
    text-align: center;
  }

  &Logo img {
    max-width: 50px;
    max-height: 50px;
    margin: 0 auto;
    padding: 10px;
  }

  &Menu {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    flex-direction: column;
    row-gap: 10px;
  }

  &Item {
    position: relative;
    color: white;

    &:hover {
      .SideBarItemSubmenu {
        display: flex;
      }
    }

    &Link {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: #232f5850;
      }
    }

    &Submenu {
      display: none;
      position: absolute;
      height: fit-content;
      text-align: left;
      max-width: unset;
      width: auto;
      z-index: 5;
      align-items: flex-start;
      row-gap: 10px;
      top: 0;
      left: calc(100% + 7px);
      border-radius: 0 4px 4px 0;
      color: white;
      background-color: darken(#0095ff, 12%);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        right: 0;
      }

      &Item {
        padding: 10px 20px;
        white-space: nowrap;

        &:hover {
          cursor: pointer;
          background-color: #232f5850;
        }
      }
    }
  }

  .SideBarItemIcon {
    font-size: 20px;
    color: white;
  }

  &Bottom {
    .SideBarItemLink {
      text-align: center;
    }
  }
}

.SideBarSubmenuContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SideBarOpen {
  .SideBarItemLinkActive {
    &::after {
      right: 0;
      border: solid 8px transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: #f0f0f1;
      top: 20px;
      margin-top: -8px;
    }
  }

  .SideBarItemSubmenuOpen {
    left: 100%;
  }
}

.SideBarItemLinkActive {
  fill: white;
  background-color: #232f5850;

  & + .SideBarItemSubmenuOpen {
    display: flex;
    position: unset;
    background-color: #232f5820;
    color: white;
    border-radius: 0;
    font-weight: normal;
    margin-left: 0;

    .SideBarItemSubmenuItem {
      color: white;
    }
  }
}
