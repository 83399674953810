.InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.InputElement {
  font-family: Montserrat;
  width: 100%;
  padding: 0.475rem 0.75rem;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;

  &:not(.InputElementError):focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: #aaa;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &Error {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.75);
  }
}

.InputLabel {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.InputErrorMessage {
  color: red;
  font-size: 14px;
}
