.header {
  z-index: 20;
  position: sticky;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding: 15px;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: relative;
}

.UserName {
  cursor: pointer;
}

.UserActionsHidden {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid gray;
  padding: 10px;
  width: 120px;
  right: 0px;
  margin-top: 10px;
  transition: ease-in-out 200ms all;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.UserActionsWrapper {
  display: block;
  position: absolute;
  background: white;
  border: 1px solid gray;
  padding: 10px;
  width: 120px;
  right: 0px;
  margin-top: 10px;
  border-radius: 4px;
  transition: ease-in-out 200ms all;

  button {
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.UserNameText {
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.IconNormal {
  transition: ease-in-out 200ms all;
}

.IconActive {
  transition: ease-in-out 200ms all;
  transform: rotate(180deg);
}
