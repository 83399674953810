.UserWrapper,
.UserEditWrapper {
  border: 1px solid black;
  padding: 5px;
  width: 385px;
}

.EmptyState {
  padding: 20px;
}
