td.MuiTableCell-body input[id='quantity'] {
  max-width: 100px;
}

.MuiTableSortLabel-root {
  line-height: 1.1rem;
  white-space: nowrap;
}

.MuiTableCell-head {
  vertical-align: top !important;

  input[type='text'] {
    max-width: 200px;
    padding: 0.225rem 0.5rem;
    font-size: 12px;
    border-radius: 0.2rem;
    margin-top: 8px;

    &::placeholder {
      color: #bbb;
    }
  }
}

.MuiOutlinedInput-root {
  input[type='tel'] {
    font: 500 14px Montserrat !important;
    height: unset !important;
    color: #495057;
    padding: 0.475rem 0.75rem;

    &::placeholder {
      color: #bbb;
    }
  }
}

th.MuiTableCell-paddingCheckbox {
  vertical-align: top !important;

  .MuiCheckbox-root {
    margin-top: 7px;
  }
}

tbody tr:hover {
  background-color: #f5f5f5 !important;

  td {
    background-color: #f5f5f5 !important;
  }
}

.table {
  &__header {
    display: flex;
    align-items: center;
    column-gap: 25px;
    padding: 20px 20px 10px;
  }

  &__switch {
    margin-left: auto;
  }
}
