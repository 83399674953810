.ContactData {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.ClientDataTitle {
  font-size: 15px;
  color: #222b45;
  font-weight: 600;
}

.ClientDataWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e6e6e6;
}

.ClientDataTitleWrapper {
  display: flex;
  column-gap: 10px;
  align-items: flex-end;

  p {
    margin: 0;
    font-size: 13px;
    color: #0095ff;
    font-weight: 600;
    cursor: pointer;
  }
}

.InputContact {
  &:nth-child(2) {
    grid-column: 2/1;
  }
}

.FormWrapper {
  display: grid;
  grid-template-columns: repeat(3, 230px);
  row-gap: 15px;
  column-gap: 15px;

  label {
    text-align: left;
    font-size: 14px;
  }
}

.ContactFormButtons {
  margin-top: 15px;
  display: flex;
  column-gap: 15px;
  grid-column: 1 / 4;
}

.ContactWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  column-gap: 15px;
  row-gap: 15px;
  margin-bottom: 25px;
}

.ContactItemHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.ContactItemOptions {
  position: absolute;
  right: 0;
  background: white;
  top: 20px;
  border: 1px solid #f5effc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  p {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.EllipsisIcon {
  cursor: pointer;
}

.ContactItem {
  border: 1px solid #f5effc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  h4,
  h3 {
    font-weight: 700;
    font-size: 18px;
    font-family: 'Cairo';
    line-height: 16px;
  }

  h4 {
    font-size: 15px;
    margin-bottom: 7px;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Cairo';
    color: #666;
  }
}

.ContactItemData {
  display: flex;
  column-gap: 15px;
}
