.FormContainer {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 30px;
}

.FormWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  min-width: 450px;
  max-width: 450px;
  row-gap: 30px;

  label {
    text-align: left;
    color: white;
  }

  .TermsInput {
    display: flex;
    grid-column: 1/3;
    flex-direction: row-reverse;

    input {
      width: auto;
    }
  }
}

.FormButtonWrapper {
  grid-column: 1/3;
  display: flex;
  margin: 0 !important;
  right: 0;
  left: 0;
}

.FormField {
  position: relative;

  &:nth-child(3),
  &:nth-child(4) {
    grid-column: 1/3;
  }

  input {
    appearance: none;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    background: transparent;
    height: 44px;
    color: white;

    &:focus {
      border-color: rgba(255, 255, 255, 0.7);
      outline: 0;
      box-shadow: 0px 10px 20px rgb(0 0 0 / 15%) !important;
    }

    &::placeholder {
      color: white;
    }
  }
}

.FormTitle {
  font-weight: bold;
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
}
