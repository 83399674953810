.Back {
  width: fit-content;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #0095ff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s linear;

  &:hover {
    color: #0286e4;
  }
}

.BackIcon {
  font-size: 12px;
}
