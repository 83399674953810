/** Normalization of HTML elements */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
}

button,
input {
  font-family: inherit;
}

:focus {
  outline: none;
}
