.QualityContainer {
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.QualityEmpty {
  padding: 20px;
}

.FormWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 450px;
  max-width: 750px;
  padding: 20px;

  label {
    text-align: left;
  }
}

.FormField {
  display: flex;
  flex-direction: column;
  row-gap: 7px;

  label {
    font-size: 14px;
    font-weight: 500;
  }

  input {
    border-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;

    &:focus {
      outline: 0;
    }
  }
}

.InputName {
  width: 100%;
}

.InputQuantity {
  width: 100%;
  max-width: 100px;
}

.InputTara {
  width: 100%;
  max-width: 90px;
}

.FormGroup {
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
}

.FormGroupCheckboxes {
  max-width: 650px;
  display: flex;
  column-gap: 20px;
  row-gap: 15px;
  flex-wrap: wrap;
}

.FormButtonWrapper {
  display: flex;
  column-gap: 10px;
  margin-top: 15px;
}
