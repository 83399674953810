.batches-modal {
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 15px 20px;

  &__cell {
    display: flex;
    align-items: center;
    column-gap: 10px;

    span {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  &__batch {
    display: flex;
    align-items: center;
    column-gap: 15px;

    label {
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
    }

    & > div {
      width: auto;
    }

    input {
      max-width: 200px;
    }
  }

  &__add {
    width: 30px;
    height: 30px;
    appearance: none;
    border: none;
    background-color: #0095ff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: darken(#0095ff, 5%);
    }
  }

  &__add-icon {
    color: white;
    font-size: 20px;
  }
}
